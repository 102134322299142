import React from 'react';
import {Link} from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Stack from '@material-ui/core/Stack';
import Button from '@material-ui/core/Button';

import FDataLogo from '@/assets/fdata.png';
import {useNestedMenu} from '@/hooks/useNestedMenu';
import TalefinLogo from '@/assets/TaleFin_Logo_White_RGB_1600px.png';
import LinkedInLogo from '@/assets/LinkedIn.png';

import {
  Copyright,
  FooterMenuItem,
  FooterRoot,
  SocialIcon,
  SocialLink,
} from './styled';
import CallToAction from '../CallToAction';
import DemoForm from '../DemoForm';

export function Footer() {
  const menuData = useNestedMenu();

  return (
    <FooterRoot>
      <Container>
        <CallToAction title="Start Now">
          <Grid item>
            <Button variant="contained" href="/about-us/contact">
              Let&apos;s Talk
            </Button>
          </Grid>
          <Grid item>
            <DemoForm />
          </Grid>
        </CallToAction>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            maxWidth={400}
            flexGrow={1}
            paddingRight={4}
            paddingBottom={2}
            xs={12}
            md={4}
          >
            <Box paddingBottom={2} textAlign="center">
              <Stack
                direction="row"
                flexWrap="wrap-reverse"
                alignItems="center"
                justifyContent="flex-start"
                spacing={2}
              >
                <img
                  style={{height: '132px', width: '100px'}}
                  src={
                    'https://storage.googleapis.com/talefin-assets-production/backend/homepage/img/CitationCertification-ISO27001_sml.png'
                  }
                  alt="ISO 27001 Certified"
                />
                <a href="https://fdata.global/anz/">
                  <img
                    style={{height: '70px', width: '126px'}}
                    src={FDataLogo}
                    alt="FDATA"
                  />
                </a>
                <Stack direction="row" spacing={2}>
                  <SocialLink href="https://au.linkedin.com/company/talefin">
                    <SocialIcon src={LinkedInLogo} alt="LinkedIn" />
                  </SocialLink>
                </Stack>
              </Stack>
            </Box>
            <Grid container justifyContent="space-between" alignItems="center">
              <Link to="/">
                <img src={TalefinLogo} alt="TaleFin Home" width="180"></img>
              </Link>
              <Copyright>
                &copy; Copyright {new Date().getFullYear()}{' '}
                <a href="https://banks.talefin.com/">TaleFin Australia</a> Pty
                Ltd. ABN{' '}
                <a href="https://www.abr.business.gov.au/ABN/View?id=71632551770">
                  71 632 551 770
                </a>
              </Copyright>
            </Grid>
          </Grid>
          {menuData.map((item) => (
            <Grid
              item
              key={item.id}
              flexGrow={1}
              xs={12}
              md={2}
              sx={{textAlign: {xs: 'center', md: 'left'}}}
            >
              <Typography variant="h5" paddingBottom={1} fontWeight={800}>
                {item?.linked !== false ? (
                  <Link to={item.slug}>{item.title}</Link>
                ) : (
                  item.title
                )}
              </Typography>
              <ul>
                {item.children?.map(
                  (child) =>
                    (!child.hidden ||
                      child.slug === '/about-us/privacy-policy' ||
                      child.slug === '/about-us/terms-and-conditions') && (
                      <FooterMenuItem key={child.id}>
                        {child?.linked !== false ? (
                          <Link to={child.slug}>{child.title}</Link>
                        ) : (
                          child.title
                        )}
                      </FooterMenuItem>
                    ),
                )}
              </ul>
            </Grid>
          ))}
        </Grid>
      </Container>
    </FooterRoot>
  );
}

export default Footer;
